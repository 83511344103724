import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "../assets/icons/download.png";
import CloseIcon from "../assets/icons/close.png";
import "./DownloadFiles.css";
import { closeDownloadModal } from "../Redux/features/DownloadFilesSlice";
import formatDate from "../utils/FormatDate";

const DownloadFilesModal = () => {
  const dispatch = useDispatch();

  // Getting the state values using useSelector hooks
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const selectedFiles = useSelector(
    (state) => state.downloadModal.selectedFiles
  );

  // Function to close the modal
  const hideDownloadModal = () => {
    dispatch(closeDownloadModal());
  };

  // Function to extract the actual file name from a complex name
  const getFileActualName = (complexName) => {
    if (typeof complexName === "string") {
      const parts = complexName.split("-");
      return parts.length > 1 ? parts[1] : complexName;
    } else {
      console.error("Expected a string for file name, but got:", complexName);
      return "unknown_filename";
    }
  };

  // Array of colors to use for file backgrounds
  const colors = [
    "#00aeff",
    "#090979",
    "#00ff26",
    "#ff00b0",
    "#ff0063",
    "#00d4ff",
    "#0800ff",
    "#ff8000",
  ];

  // Mapping dates to colors
  const dateColorMap = {};
  let colorIndex = 0;

  // Safeguard in case `selectedFiles` is undefined or empty
  if (selectedFiles && selectedFiles.length > 0) {
    selectedFiles.forEach((file) => {
      if (file.date && !dateColorMap[file.date]) {
        dateColorMap[file.date] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });
  }

  return (
    <div
      className={`modal downloadFiles ${
        displayDownloadModal ? "visible" : "hidden"
      }`}
    >
      <div className="modal-wrapper">
        <div className="modal-wrapper-container">
          <div className="close-icon" onClick={hideDownloadModal}>
            <img src={CloseIcon} alt="Close" />
          </div>
          <p className="large-headers">DOWNLOAD A FILE</p>
          <div className="grid">
            {selectedFiles && selectedFiles.length > 0 ? (
              selectedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: file.date
                      ? dateColorMap[file.date]
                      : "#fff",
                  }}
                >
                  {file.date && (
                    <p className="uploaded-date">{formatDate(file.date)}</p>
                  )}
                  <p>
                    <a
                      href={file?.downloadURL}
                      download={
                        getFileActualName(file?.fileName) || `file_${index + 1}`
                      }
                    >
                      {`Download file ${index + 1}`}
                      <img
                        src={DownloadIcon}
                        alt="icon"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </a>
                  </p>
                </div>
              ))
            ) : (
              <p>No files available for download</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadFilesModal;
